import { DesignQuery } from '@generated/GraphqlTypes';
import Design from '@localTypes/Design';
import slug from '@helpers/slug';
import { getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';

const decodeDesign = (data: DesignQuery): Design => {
  const images = (data.markdownRemark?.frontmatter?.images || []).map(a =>
    getImage(a as ImageDataLike)
  ) as IGatsbyImageData[];

  const palettes = (data.markdownRemark?.frontmatter?.palettes || []).map(a => {
    const images = (a?.images || []).map(a =>
      getImage(a as ImageDataLike)
    ) as IGatsbyImageData[];
    return {
      title: `${a?.title}`,
      images,
    };
  });

  return {
    slug: slug(`${data.markdownRemark?.frontmatter?.name}`),
    title: `${data.markdownRemark?.frontmatter?.title}`,
    hidden: data.markdownRemark?.frontmatter?.hidden ?? false,
    images,
    paletteTitle: `${data.markdownRemark?.frontmatter?.paletteTitle}`,
    paletteBlurb: `${data.markdownRemark?.frontmatter?.paletteBlurb}`,
    palettes,
    flyerPdf: `${data.markdownRemark?.frontmatter?.flyerPdf?.publicURL}`,
    video: `${data.markdownRemark?.frontmatter?.video}`,
    body: `${data.markdownRemark?.html}`,
  };
};

export default decodeDesign;
